<template>
  <div class="home">
    <div class="title">首页</div>
    <el-card class="box-card">
      <div class="data">前台数据概况</div>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple1">
            <div class="img">
              <img src="../../assets/loginTotal.png" alt="" />
            </div>
            <div class="content">
              <p>今日登录次数</p>
              <p class="total">{{ loginTotal }}</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple2">
            <div class="img">
              <img src="../../assets/fileTotal.png" alt="" />
            </div>
            <div class="content">
              <p>今日上传文件数量</p>
              <p class="total">
                {{ fileTotal }}
              </p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple3">
            <div class="img">
              <img src="../../assets/registUser.png" alt="" />
            </div>
            <div class="content">
              <p>今日注册用户</p>
              <p class="total">{{ registTotal }}</p>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple4">
            <div class="img">
              <img src="../../assets/userTotal.png" alt="" />
            </div>
            <div class="content">
              <p>总计注册用户</p>
              <p class="total">{{ userTotal }}</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="loginTotal-card">
      <div class="data">前台登录次数统计</div>
      <div class="button">
        <span @click="handleClick('week')" style="margin-right: 15px" :class="tag == 'week' ? 'active' : ''">本周</span>
        <span @click="handleClick('month')" :class="tag == 'month' ? 'active' : ''">本月</span>
      </div>
      <div ref="loginTotalChart" id="loginTotal-chart"></div>
    </el-card>
    <el-card class="fileTotal-card">
      <div class="data">前台上传文件次数统计</div>
      <div class="button">
        <span @click="handleChange('week')" style="margin-right: 15px" :class="flag == 'week' ? 'active' : ''">本周</span>
        <span @click="handleChange('month')" :class="flag == 'month' ? 'active' : ''">本月</span>
      </div>
      <div ref="fileTotalChart" id="fileTotal-chart"></div>
    </el-card>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from "vue";
import * as echarts from "echarts";
import { getRoleList } from '../../api/role';
export default {
  setup() {
    const state = reactive({
      loginTotal: 28,
      fileTotal: 30,
      registTotal: 3,
      userTotal: 30,
      tag: "week",
      flag: "week",
      loginCount: {
        title: {
          subtext: "（单位: 次）",
          text: "登录次数",
          textStyle: {
            color: "#000",
            fontWeight: "bold",
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "登录次数",
            type: "line",
            stack: "Total",
            data: [120, 132, 101, 134, 90, 230, 210],
          },
        ],
      },
      fileCount: {
        title: {
          subtext: "（单位: 次）",
          text: "上传文件次数",
          textStyle: {
            color: "#000",
            fontWeight: "bold",
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "上传文件次数",
            type: "line",
            stack: "Total",
            data: [11, 13, 10, 2, 5, 0, 0],
          },
        ],
      },
    });
    const getData = () => {
      state.loginCount.series[0].data = [14, 23, 22, 12, 34, 0, 0];
      state.loginCount.xAxis.data = [
        "周一",
        "周二",
        "周三",
        "周四",
        "周五",
        "周六",
        "周日",
      ];
      state.fileCount.series[0].data = [3, 2, 6, 7, 5, 0, 0];
      state.fileCount.xAxis.data = [
        "周一",
        "周二",
        "周三",
        "周四",
        "周五",
        "周六",
        "周日",
      ];
    };
    getData();
    const handleClick = (item) => {
      state.tag = item;
      if (item == "week") {
        state.loginCount.series[0].data = [14, 23, 22, 12, 34, 0, 0];
        state.loginCount.xAxis.data = [
          "周一",
          "周二",
          "周三",
          "周四",
          "周五",
          "周六",
          "周日",
        ];
        loginTotalChartinit();
      } else {
        state.loginCount.series[0].data = [
          15,
          32,
          34,
          25,
          86,
          37,
          13,
          15,
          32,
          34,
          25,
          86,
          37,
          13,
          8,
        ];
        state.loginCount.xAxis.data = [
          "2013/2/1",
          "2013/2/4",
          "2013/2/5",
          "2013/2/6",
          "2013/2/7",
          "2013/2/8",
          "2013/2/18",
          "2013/2/19",
          "2013/2/20",
          "2013/2/21",
          "2013/2/22",
          "2013/2/25",
          "2013/2/26",
          "2013/2/27",
          "2013/2/28",
        ];
        loginTotalChartinit();
      }
    };
    const handleChange = (item) => {
      state.flag = item;
      if (item == "week") {
        state.fileCount.series[0].data = [3, 2, 6, 7, 5, 0, 0];
        state.fileCount.xAxis.data = [
          "周一",
          "周二",
          "周三",
          "周四",
          "周五",
          "周六",
          "周日",
        ];
        fileTotalChartinit();
      } else {
        state.fileCount.series[0].data = [
          15,
          32,
          34,
          25,
          86,
          37,
          13,
          15,
          32,
          34,
          25,
          86,
          37,
          13,
          8,
        ];
        state.fileCount.xAxis.data = [
          "2013/2/1",
          "2013/2/4",
          "2013/2/5",
          "2013/2/6",
          "2013/2/7",
          "2013/2/8",
          "2013/2/18",
          "2013/2/19",
          "2013/2/20",
          "2013/2/21",
          "2013/2/22",
          "2013/2/25",
          "2013/2/26",
          "2013/2/27",
          "2013/2/28",
        ];
        fileTotalChartinit();
      }
    };
    let loginTotalChart = ref(null);
    let fileTotalChart = ref(null);
    const loginTotalChartinit = () => {
      // 初始化
      let lineChart = echarts.init(loginTotalChart.value);
      lineChart.setOption(state.loginCount);
      window.onresize = function () {
        //自适应大小, 不用的话不会自适应大小。
        lineChart.resize();
      };
    };
    const fileTotalChartinit = () => {
      let lineChart1 = echarts.init(fileTotalChart.value);
      lineChart1.setOption(state.fileCount);
      window.onresize = function () {
        //自适应大小, 不用的话不会自适应大小。
        lineChart1.resize();
      };
    };
    //为了验证token有没有过期，随便调一个接口，这个接口跟首页没有任何关系
    const getList = () => {
      let data = {
        deptName: '',
        endTime: "",
        roleName: '',
        startTime: "",
        pageNum: 1,
        pageSize: 10,
      }
      getRoleList(data);
    }

    onMounted(() => {
      loginTotalChartinit();
      fileTotalChartinit();
      getList();
    });
    return {
      ...toRefs(state),
      handleClick,
      handleChange,
      fileTotalChart,
      loginTotalChart,
    };
  },
};
</script>

<style scoped lang="scss">
.home {
  .title {
    margin-bottom: 30px;
    font-weight: 700;
  }
  .data {
    font-weight: 700;
    margin-bottom: 20px;
  }
  .box-card {
    .img {
      float: left;
      width: 30px;
      height: 30px;
      margin: 30px;
      background: #fff;
      box-sizing: border-box;
    }
    .content {
      width: 100%;
      height: 100%;
      padding: 20px 0;
      box-sizing: border-box;
      p {
        font-size: 14px;
      }
      .total {
        font-weight: 700;
        font-size: 20px;
        margin-top: 10px;
      }
    }
    .el-row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .el-col {
      border-radius: 4px;
    }
    .bg-purple-dark {
      background: #99a9bf;
    }
    .bg-purple1 {
      background: #ff7970;
    }
    .bg-purple2 {
      background: #ffd344;
    }
    .bg-purple3 {
      background: #648cff;
    }
    .bg-purple4 {
      background: #4fccb0;
    }
    .bg-purple-light {
      background: #e5e9f2;
    }
    .grid-content {
      border-radius: 4px;
      min-height: 36px;
      height: 90px;
      color: #fff;
      font-size: 12px;
    }
    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }
    .acceptence-container-sum {
      // flex: 2;
      background: #ffffff;
      // margin-left: 10px;
      // padding: 20px 20px 0 20px;
      border-radius: 10px;
      .line-chart {
        width: 100%;
        height: 280px;
      }
    }
  }
  .loginTotal-card {
    margin: 30px 0;
    width: 100%;
    height: 510px;
    position: relative;
    .button {
      position: absolute;
      top: 66px;
      right: 86px;
      z-index: 99;
      span {
        color: #38a28e;
        font-weight: 700;
        cursor: pointer;
      }
      .active {
        color: #000;
      }
    }
    #loginTotal-chart {
      width: 100%;
      height: 440px;
    }
  }
  .fileTotal-card {
    margin: 30px 0;
    width: 100%;
    height: 510px;
    position: relative;
    .button {
      position: absolute;
      top: 66px;
      right: 86px;
      z-index: 99;
      span {
        color: #38a28e;
        font-weight: 700;
        cursor: pointer;
      }
      .active {
        color: #000;
      }
    }
    #fileTotal-chart {
      width: 100%;
      height: 440px;
    }
  }
}
</style>
